<template>
  <div>
    <v-form v-model="mainFormValid" class="pa-4">
      <v-text-field
        v-model="internalValue.name"
        :rules="$options.rules.name"
        label="Nom"
        type="text"
        @input="change"
      />

      <v-textarea
        v-model="internalValue.description"
        label="Description"
        rows="2"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.partType"
        :rules="$options.rules.partType"
        label="Type de référence"
        :item-list="partTypeList"
        item-text="name"
        @input="change"
      />

      <ag-autocomplete
        v-if="levelChoice"
        v-model="internalValue.level"
        :rules="$options.rules.level"
        label="Type de relevé"
        :item-list="['Opérations', 'Pièces']"
        @input="change"
      />

      <ag-switch
        v-model="internalValue.destroy"
        inset
        label="Engendre la desctruction de la pièce"
        @input="change"
      />

      <ag-switch
        v-model="internalValue.sampling"
        inset
        label="Relevé non obligatoire (échantillonnage)"
        @input="change"
      />

      <ag-autocomplete
        v-model="internalValue.type"
        :disabled="!levelChoice"
        :rules="$options.rules.type"
        label="Type de valeur"
        :item-list="valueTypeList"
        @input="change"
      />

      <v-slide-y-transition>
        <v-text-field
          v-if="isMaskAvailable"
          v-model="internalValue.mask"
          label="Mask"
          @input="change"
        />
      </v-slide-y-transition>

      <v-slide-y-transition>
        <ag-switch
          v-if="internalValue.type === 'Nombre' || internalValue.type === 'Perméabilité'"
          v-model="internalValue.interval"
          inset
          label="Ajouter un intervalle de tolérance"
          @input="change"
        />
      </v-slide-y-transition>
      <v-slide-y-transition>
        <v-text-field
          v-if="internalValue.interval"
          v-model="internalValue.min"
          :rules="$options.rules.min"
          type="number"
          label="Min"
          @input="change"
        />
      </v-slide-y-transition>

      <v-slide-y-transition>
        <v-text-field
          v-if="internalValue.interval"
          v-model="internalValue.max"
          :rules="$options.rules.max"
          type="number"
          label="Max"
          @input="change"
        />
      </v-slide-y-transition>
    </v-form>
  </div>
</template>

<script>
import Repositories from 'src/repositories'
import Helpers from 'src/utils/helpers'
import FormRules from 'src/utils/form-rules'
import AgSwitch from 'src/components/common/AgSwitch'
import AgAutocomplete from 'src/components/common/AgAutocomplete'

export default {
  name: 'RecordTypeForm',
  components: { AgSwitch, AgAutocomplete },
  props: {
    value: {
      type: Object,
      required: true,
    },
    levelChoice: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    internalValue: null,
    mainFormValid: false,
    partTypeList: [],
  }),
  computed: {
    isMaskAvailable () {
      return this.internalValue.type === 'Nombre' || this.internalValue.type === 'Texte'
    },
    isValidRecordType () {
      return this.mainFormValid
    },
    valueTypeList () {
      return [
        'Date',
        'OK/KO',
        'Texte',
        'Nombre',
        'Collodion',
        'Bobine',
        'Faisceau',
        'Perméabilité',
      ]
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
        this.$emit('validate', this.isValidRecordType)
      },
    },
    isValidRecordType: {
      immediate: true,
      handler () {
        this.$emit('validate', this.isValidRecordType)
      },
    },
    isMaskAvailable: {
      immediate: false,
      handler () {
        this.internalValue.mask = ''
      },
    },
    'internalValue.type': {
      immediate: true,
      handler (val) {
        if (val !== 'Nombre' && val !== 'Perméabilité') {
          this.internalValue.interval = false
        }
        if (this.internalValue.type === 'Collodion' || this.internalValue.type === 'Bobine' || this.internalValue.type === 'Faisceau') {
          this.internalValue.part = true
        }
        else {
          this.internalValue.part = false
        }
      },
    },
  },
  async mounted () {
    this.partTypeList = await Repositories.partType.getList()
  },
  methods: {
    change () {
      this.$emit('input', this.internalValue)
      this.$emit('validate', this.isValidRecordType)
    },
  },
  rules: {
    name: [
      FormRules.required('requis'),
    ],
    partType: [
      FormRules.required('requis'),
    ],
    level: [
      FormRules.required('requis'),
    ],
    type: [
      FormRules.required('requis'),
    ],
    min: [
      FormRules.required('requis'),
    ],
    max: [
      FormRules.required('requis'),
    ],
  },
}
</script>
